// src/pages/Login/Login.jsx

import React, { useState } from 'react';
import styles from './Login.module.css';
import axios from 'axios';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const Login = () => {
  const { login } = useAuth();
  const [isTraditional, setIsTraditional] = useState(false);
  const [accountId, setAccountId] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleToggle = () => {
    setIsTraditional(!isTraditional);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isTraditional) {
        // Traditional login
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/login/traditional`, {
          username,
          password,
        });
        login(response.data.token);
      } else {
        // Private login
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/login/private`, {
          accountId,
        });
        login(response.data.token);
      }
      navigate('/');
    } catch (error) {
      console.error('Login failed:', error);
      alert('Login failed! Please check your credentials.');
    }
  };

  return (
    <div className={styles.loginContainer}>
      <motion.form
        className={styles.form}
        onSubmit={handleSubmit}
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <h2>Login</h2>
        <div className={styles.toggleContainer}>
          <label className={styles.switch}>
            <input type="checkbox" checked={isTraditional} onChange={handleToggle} />
            <span className={`${styles.slider} ${styles.round}`}></span>
          </label>
          <span>{isTraditional ? 'Traditional Account' : 'Private Account'}</span>
        </div>
        {!isTraditional ? (
          <div className={styles.inputGroup}>
            <label htmlFor="accountId">Account ID</label>
            <input
              type="text"
              id="accountId"
              value={accountId}
              onChange={(e) => setAccountId(e.target.value)}
              required
              placeholder="Enter your Account ID"
            />
          </div>
        ) : (
          <>
            <div className={styles.inputGroup}>
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                minLength={6}
                placeholder="Enter your Username"
              />
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Enter your Password"
              />
            </div>
          </>
        )}
        <button type="submit" className={styles.submitButton}>Login</button>
      </motion.form>
    </div>
  );
};

export default Login;
