// src/App.jsx

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Sidebar from './components/Sidebar/Sidebar';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import Placeholder from './pages/Placeholder/Placeholder';
import { AuthProvider } from './context/AuthContext';
import './styles/global.css';

const App = () => {
  const [theme, setTheme] = useState('dark');

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(prev => (prev === 'dark' ? 'light' : 'dark'));
  };

  return (
    <AuthProvider>
      <Router>
        <Navbar toggleTheme={toggleTheme} />
        <Sidebar />
        <main style={{ marginLeft: '80px', paddingTop: '60px', paddingLeft: '20px', paddingRight: '20px' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            {/* Placeholder routes */}
            <Route path="/game/:id" element={<Placeholder />} />
            <Route path="/profile" element={<Placeholder />} />
            <Route path="/settings" element={<Placeholder />} />
            <Route path="/history" element={<Placeholder />} />
            {/* Add more routes as needed */}
          </Routes>
        </main>
      </Router>
    </AuthProvider>
  );
};

export default App;
