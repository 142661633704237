// src/pages/Home/Home.jsx

import React from 'react';
import styles from './Home.module.css';
import GameCard from '../../components/GameCard/GameCard';
import { motion } from 'framer-motion';

const Home = () => {
  const games = [
    { title: 'Slots', image: '/Assets/images/slotMachine.gif', path: '/game/slots' },
    { title: 'Roulette', image: '/Assets/images/rouletteWheel.gif', path: '/game/roulette' },
    { title: 'Blackjack', image: '/Assets/images/blackjack.gif', path: '/game/blackjack' },
    // Add more games as needed
  ];

  return (
    <div className={styles.homeContainer}>
      <motion.div
        className={styles.announcement}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2>Opening Soon</h2>
        <p>Experience the best in privacy, speed, and fairness.</p>
        <ul className={styles.bulletPoints}>
          <li>• Privacy</li>
          <li>• Speed</li>
          <li>• Fairness</li>
        </ul>
      </motion.div>
      <div className={styles.gamesGrid}>
        {games.map(game => (
          <GameCard key={game.title} title={game.title} image={game.image} path={game.path} />
        ))}
      </div>
    </div>
  );
};

export default Home;
