// src/components/Sidebar/Sidebar.jsx

import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Sidebar.module.css';

const Sidebar = () => {
  const games = [
    { name: 'Slots', icon: '/assets/images/slots.png', path: '/game/slots' },
    { name: 'Roulette', icon: '/assets/images/roulette.png', path: '/game/roulette' },
    { name: 'Blackjack', icon: '/assets/images/blackjack.png', path: '/game/blackjack' },
    // Add more games as needed
  ];

  const otherPages = [
    { name: 'Profile', icon: '/assets/images/profile.png', path: '/profile' },
    { name: 'Settings', icon: '/assets/images/settings.png', path: '/settings' },
    { name: 'History', icon: '/assets/images/history.png', path: '/history' },
    // Add more pages as needed
  ];

  return (
    <aside className={styles.sidebar}>
      <div className={styles.buttonsContainer}>
        {games.map(game => (
          <NavLink
            to={game.path}
            key={game.name}
            className={({ isActive }) =>
              isActive ? `${styles.button} ${styles.active}` : styles.button
            }
          >
            <img src={game.icon} alt={game.name} className={styles.icon} />
          </NavLink>
        ))}
        {/* Add other page buttons */}
        {otherPages.map(page => (
          <NavLink
            to={page.path}
            key={page.name}
            className={({ isActive }) =>
              isActive ? `${styles.button} ${styles.active}` : styles.button
            }
          >
            <img src={page.icon} alt={page.name} className={styles.icon} />
          </NavLink>
        ))}
      </div>
    </aside>
  );
};

export default Sidebar;
