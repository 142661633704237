// src/hooks/useAuth.jsx

import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

/**
 * Custom hook to access authentication context.
 * @returns {Object} Authentication context value.
 */
const useAuth = () => {
  return useContext(AuthContext);
};

export default useAuth;
