// src/components/Navbar/Navbar.jsx

import React, { useContext, useState, useEffect, useRef } from 'react';
import styles from './Navbar.module.css';
import useAuth from '../../hooks/useAuth';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Navbar = ({ toggleTheme }) => {
  const { isAuthenticated, user, logout } = useAuth();
  const [balanceOpen, setBalanceOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [balance, setBalance] = useState('0.0000');
  const navigate = useNavigate();
  const balanceRef = useRef();
  const profileRef = useRef();

  // Fetch balance from API (placeholder)
  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/balance`, {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        });
        setBalance(response.data.balance);
      } catch (error) {
        console.error('Failed to fetch balance:', error);
      }
    };

    if (isAuthenticated) {
      fetchBalance();
    }
  }, [isAuthenticated, user]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (balanceRef.current && !balanceRef.current.contains(event.target)) {
        setBalanceOpen(false);
      }
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setProfileOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleBalanceClick = () => {
    setBalanceOpen(!balanceOpen);
    setProfileOpen(false);
  };

  const handleProfileClick = () => {
    setProfileOpen(!profileOpen);
    setBalanceOpen(false);
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.logo}>
        <h1>Lazy<span>Bet</span></h1>
      </div>
      <div className={styles.navItems}>
        {isAuthenticated && (
          <div className={styles.balance} onClick={handleBalanceClick} ref={balanceRef}>
            BTC Balance: {balance}
            <AnimatePresence>
              {balanceOpen && (
                <motion.div
                  className={styles.dropdown}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.3 }}
                >
                  <button
                    className={styles.dropdownItem}
                    onClick={() => navigate('/deposit')}
                  >
                    Deposit
                  </button>
                  <button
                    className={styles.dropdownItem}
                    onClick={() => navigate('/withdraw')}
                  >
                    Withdraw
                  </button>
                  <div className={styles.dropdownItem}>
                    <input type="checkbox" id="usd" />
                    <label htmlFor="usd">Display in USD</label>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )}
        <div className={styles.profile} onClick={handleProfileClick} ref={profileRef}>
          <img src="/assets/images/profile.gif" alt="Profile" className={styles.profileIcon} />
          <AnimatePresence>
            {profileOpen && (
              <motion.div
                className={styles.dropdown}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.3 }}
              >
                {isAuthenticated ? (
                  <>
                    <button
                      className={styles.dropdownItem}
                      onClick={() => navigate('/profile')}
                    >
                      Profile
                    </button>
                    <button
                      className={styles.dropdownItem}
                      onClick={() => navigate('/settings')}
                    >
                      Settings
                    </button>
                    <button
                      className={styles.dropdownItem}
                      onClick={() => navigate('/history')}
                    >
                      History
                    </button>
                    <button
                      className={styles.dropdownItem}
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className={styles.dropdownItem}
                      onClick={() => navigate('/register')}
                    >
                      Register
                    </button>
                    <button
                      className={styles.dropdownItem}
                      onClick={() => navigate('/login')}
                    >
                      Login
                    </button>
                  </>
                )}
                <div className={styles.dropdownItem}>
                  <input type="checkbox" id="theme" onChange={toggleTheme} />
                  <label htmlFor="theme">Dark/Light Mode</label>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
