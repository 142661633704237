// src/pages/Placeholder/Placeholder.jsx

import React from 'react';
import styles from './Placeholder.module.css';

const Placeholder = () => {
  return (
    <div className={styles.placeholderContainer}>
      <h2>Page Coming Soon!</h2>
      <p>This page is under construction.</p>
    </div>
  );
};

export default Placeholder;
