// src/context/AuthContext.jsx

import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import useAuth from '../hooks/useAuth';

/**
 * AuthContext provides authentication state and actions.
 */
export const AuthContext = createContext({
  isAuthenticated: false,
  user: null,
  login: () => {},
  logout: () => {},
});

/**
 * AuthProvider component that wraps around children components
 * and provides authentication context.
 */
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  // Check for existing token on component mount
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // Optionally, validate token with backend
      setIsAuthenticated(true);
      setUser({ token }); // Extend with more user data as needed
    }
  }, []);

  /**
   * Login function to authenticate the user.
   * @param {string} token - JWT token received from backend.
   */
  const login = (token) => {
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
    setUser({ token });
  };

  /**
   * Logout function to de-authenticate the user.
   */
  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUser(null);
  };

  /**
   * Optional: Fetch user details if needed.
   */
  // useEffect(() => {
  //   const fetchUserDetails = async () => {
  //     if (user?.token) {
  //       try {
  //         const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/profile`, {
  //           headers: {
  //             Authorization: `Bearer ${user.token}`,
  //           },
  //         });
  //         setUser(prev => ({ ...prev, ...response.data }));
  //       } catch (error) {
  //         console.error('Failed to fetch user details:', error);
  //       }
  //     }
  //   };
  //   fetchUserDetails();
  // }, [user?.token]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
