// src/components/GameCard/GameCard.jsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './GameCard.module.css';
import { motion } from 'framer-motion';

const GameCard = ({ title, image, path }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(path);
  };

  return (
    <motion.div
      className={styles.card}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={handleClick}
    >
      <img src={image} alt={title} className={styles.cardImage} />
      <h3 className={styles.cardTitle}>{title}</h3>
    </motion.div>
  );
};

export default GameCard;
