// src/pages/Register/Register.jsx

import React, { useState } from 'react';
import styles from './Register.module.css';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const [isTraditional, setIsTraditional] = useState(false);
  const [accountId, setAccountId] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  // Generate account ID on component mount for private accounts
  React.useEffect(() => {
    const fetchAccountId = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/register/private/generate-id`);
        setAccountId(response.data.accountId);
      } catch (error) {
        console.error('Failed to generate account ID:', error);
      }
    };
    if (!isTraditional) {
      fetchAccountId();
    }
  }, [isTraditional]);

  const handleToggle = () => {
    setIsTraditional(!isTraditional);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isTraditional && password !== confirmPassword) {
      alert("Passwords don't match!");
      return;
    }
    try {
      if (isTraditional) {
        // Traditional registration
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/register/traditional`, {
          username,
          password,
          email,
        });
        alert('Registration successful! You can now log in.');
      } else {
        // Private registration
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/register/private`, {
          accountId,
        });
        alert(`Registration successful! Your Account ID: ${accountId}`);
      }
      navigate('/login');
    } catch (error) {
      console.error('Registration failed:', error);
      alert('Registration failed! Please try again.');
    }
  };

  return (
    <div className={styles.registerContainer}>
      <motion.form
        className={styles.form}
        onSubmit={handleSubmit}
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <h2>Register</h2>
        <div className={styles.toggleContainer}>
          <label className={styles.switch}>
            <input type="checkbox" checked={isTraditional} onChange={handleToggle} />
            <span className={`${styles.slider} ${styles.round}`}></span>
          </label>
          <span>{isTraditional ? 'Traditional Account' : 'Private Account'}</span>
        </div>
        <AnimatePresence>
          {!isTraditional ? (
            <motion.div
              className={styles.privateInfo}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <p>Your Account ID:</p>
              <h3 className={styles.accountId}>{accountId}</h3>
              <p>Please keep this ID safe as it is used for login.</p>
            </motion.div>
          ) : (
            <motion.div
              className={styles.traditionalInfo}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div className={styles.inputGroup}>
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                  minLength={6}
                  placeholder="Enter your Username"
                />
              </div>
              <div className={styles.inputGroup}>
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  placeholder="Enter your Password"
                />
              </div>
              <div className={styles.inputGroup}>
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  placeholder="Confirm your Password"
                />
              </div>
              <div className={styles.inputGroup}>
                <label htmlFor="email">Email (optional)</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your Email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <button type="submit" className={styles.submitButton}>Register</button>
      </motion.form>
    </div>
  );
};

export default Register;
